<template>
<div class="news">
                   <van-list
        v-model:loading="loading"
        :finished="finished"
        :finished-text="commentList.length > 5 || praiseList.length > 5 ? '没有更多了':''"
        @load="onLoad"
        >
    <van-tabs v-model:active="active"  @click="onChanges" border="false" tab-class="tab-class" nav-class="nav-class" tab-active-class="tab-active-class" class="tabs">
        <van-tab title="评论" name="0" :dot="comment_notice_count != 0">
            <Nodata v-if="commentList && commentList.length == 0" :bottomBg="true" ></Nodata>
            <div class="list">
      
                <div :class="item.read_at == null ? 'list-item list-item-a':'list-item '" v-for="(item,index) in commentList" :key="index" @click="gocomment(item.data.bounty_id,item.data.model_type,'0',item.id)" >
                    <img class="list-item-img" :src="item.data.commentUser && item.data.commentUser.avatar"/>
                    <div class="list-item-content">
                        <div class="list-item-content-title vertical-center white-space">
                          <div class="list-item-content-title-name white-space">{{item.data.commentUser.nickname}}</div>
                          <img src="~assets/images/public/vip1.png" v-if="item.data && item.data.commentUser && item.data.commentUser.level == 'vip1'" class="vipimg">
                          <img src="~assets/images/public/captain.png" v-if="item.data && item.data.commentUser && item.data.commentUser.is_team_master "  class="captain" />
                          <img src="~assets/images/public/captain-s.png" v-if="item.data && item.data.commentUser && item.data.commentUser.is_group_team_master "  class="captain-s" />
                          <img :src="item.data && item.data.commentUser && item.data.commentUser.picture" v-if="item.data && item.data.commentUser && item.data.commentUser.picture"  class="labelimg">
                          <img src="~assets/images/public/36ce.png" v-else class="labelimg">
                        </div>
                        <div class="list-item-content-center" v-if="item?.is_delete">这条评论已删除</div>
                        <div class="list-item-content-center" v-else>{{item.data.content}}</div>
                        <div class="list-item-content-bottom vertical-center">
                            <div class="list-item-content-bottom-l">{{item.create_time}}</div>
                            <!-- <div class="list-item-content-bottom-e vertical-center" @click.stop="evaluate(item.data.comment_id,item.data.model_id,item.data.id)" >
                                <img src="~assets/images/index/talks.png" class="list-item-content-bottom-img" alt="" /> -->
                                <!-- {{item.comment_num}} -->
                                <!-- 回复
                            </div> -->
                            <!-- <div class="list-item-content-bottom-e" catchtap="Praise" data-id="{{item.id}}" data-type="comment">
                                <image wx:if="{{osscdn !== ''}}" src="{{osscdn + 'index/up.png'}}" class="list-item-content-bottom-img"></image>
                                {{item.praise_num}}
                            
                            </div> -->
                        </div>
                    </div>
                    <div class="list-item-info">{{item.data.select_content}}</div>
                </div>
                  
            </div>
        </van-tab>
        <van-tab title="点赞" name="1" :dot="praise_notice_count != 0">
            <Nodata v-if="praiseList && praiseList.length == 0" :bottomBg="true" ></Nodata>
            <div class="list">
                <div class="list-item" v-for="(item,index) in praiseList" :key="index" @click="gocomment(item.id,item.type,'1')">
                    <img class="list-item-img" :src="item.avatar"/>
                    <div class="list-item-content">
                        <div class="list-item-content-title vertical-center white-space">
                          <div class="list-item-content-title-name white-space">{{item.title}}</div>
                          <img src="~assets/images/public/vip1.png" v-if="item.level == 'vip1'" class="vipimg"><img :src="item.picture" v-if="item.picture"  class="labelimg"><img src="~assets/images/public/36ce.png" v-else class="labelimg"><span v-if="item.count >= 3">等{{item.count}}人</span>{{item.explain}}</div>
                        <div class="list-item-content-bottom">
                            <div class="list-item-content-bottom-l">{{item.create_time}}</div>
                        </div>
                    </div>
                    <div class="list-item-info">{{item.content}}</div>
                </div>
            </div>
        </van-tab>
    </van-tabs>
    </van-list>
    <van-popup  v-model:show="show" position="bottom"  :style="{height: '32%'}"  closeable>
        <van-field v-model="content" placeholder="请输入评论" border="{{ false }}"  type="textarea"   rows="5" autosize maxlength='300' show-word-limit>


        </van-field>
        <div class="popup-btn" @click="commentsub()">确定</div>
    </van-popup>
</div>

</template>

<script>
export default {
  name: "user.new",
  data(){
    return{
        active: 0,
        commentList:[],
        page:1,
        load:true,
        praiseList:[],
        show:false,
        model_id:'',
        comment_id:'',
        parent_id:'',
        osscdn:'',
        value:'',
         loading: false,
            finished: false,
            content:'',
             praise_notice_count:0,
        comment_notice_count:0
    }
  },

    created() {
        if (this.$store.getters.acts) {
        this.active = this.$store.getters.acts
         this.$store.commit('M_ACTS','0')
      }
       this.getinformationamount()
  //  if(this.active == 0){
  //           this.commentList=[]
  //       this.page=1
  //       this.load=true
  //           this.communityList()
  //         }else if(this.active == 1){
  //           this.page=1
  //       this.load=true
  //       this.praiseList=[]
  //           this.getpraiseList()
  //         }
  },
  methods:{
         //未读数量
 getinformationamount(){
    let url = this.$api.informationamount 
     this.$http.get(url, true).then(res => {
      if(res.data.success){
        this.praise_notice_count = res.data.data.praise_notice_count
        this.comment_notice_count = res.data.data.comment_notice_count
      }
    })
    },
     //评论列表
 communityList(){
    let url = this.$api.communityInformation + '?model_type=comment&pageSize=20&page=' + this.page
     this.$http.get(url, true).then(res => {
      if(res.data.success){
          this.loading = false;
        if(res.data.data.length){
this.finished = false;
          this.commentList = this.commentList.concat(res.data.data)
          this.page ++
          this.getinformationamount()
        }else{
          this.load = false
          this.finished = true;
        }
      }
    })
    },
    getpraiseList(){
        let url = this.$api.communityInformation + '?model_type=praise&pageSize=15&page=' + this.page
          this.$http.get(url, true).then(res => {
          if(res.data.success){
              this.loading = false;
            if(res.data.data.length){
                this.finished = false;
              this.praiseList = this.praiseList.concat(res.data.data)
              this.page ++
              this.getinformationamount()
            }else{
              this.load = false
              this.finished = true;
            }
          }
        })
        },
        //跳转
        gocomment(id,type,num,uuid){
          this.$store.commit('M_ACTS',num)
            if(type == 'bounty'){
                  this.$router.push({path: '/community/show',query: {id: id,type:'news',uuid:uuid}})
            }
          else if(type == 'electron'){
              this.$router.push({path: '/community/resources-show',query: {id: id,type:'news',uuid:uuid}})
          }
            // else if(type == 'group'){
            //     wx.navigateTo({
            //         url: '/pages/user/study/show/show?id=' + id + '&evaluate=true'
            //       })
            // }
        },
        // evaluate(commentid,modelid,parentid){
        //     this.show= true 
        //         this.model_id=modelid
        //         this.parent_id=parentid
        //         this.comment_id=commentid
        // },
        onChanges(event) {
          this.active= event
          this.load=true
          this.page=1
      
          if(this.active == 0){
            this.commentList=[]
        this.page=1
        this.load=true
            this.communityList()
          }else if(this.active == 1){
            this.page=1
        this.load=true
        this.praiseList=[]
            this.getpraiseList()
          }
     
          },
          //评论提交
          commentsub(event){
            let url = this.$api.CommunityComment 
            let data = {
              model_id:this.model_id,
              comment_id:this.comment_id,
              parent_id:this.parent_id,
              content:this.content,
              source:'applets'
            }
           this.$http.post(url,data, true).then(res => {
              if(res.data.success){
                    this.show=false
                    this.content=''
                    this.value=''
                  this.reset()
              }
            })
          },
           //点赞
    // Praise(event){
    //     let url = this.$api.communityPraise
    //     let data = {
    //       model_id:event.currentTarget.dataset.id,
    //       model_type:event.currentTarget.dataset.type
    //     }
    //     Request.wxRequest(url, 'POST', data, true).then(res => {
    //       if(res.data.success){
    //       //   wx.showToast({
    //       //     title: '点赞成功',
    //       //     icon: 'success',
    //       //     duration: 2000
    //       // })
    //       this.reset()
    //       }
    //     })
    //   },
      reset(){
        this.commentList=[]
          this.page=1
          this.load=true
          this.praiseList=[]
        this.communityList()
        this.getpraiseList()
      },
        onLoad(){
            
            if(this.active == 0 && this.load){
                this.communityList()
            }  else if(this.active == 1 && this.load){
                this.getpraiseList()
            }
        },
  },
  components:{
  },
   computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/new/new.scss";
.vipimg{
  width: 28/$r;
  height: 25/$r;
  margin-left: 7/$r;
}
.captain{
  width: 77/$r;
  height: 29/$r;
  margin-left: 7/$r;
}
.captain-s{
  width: 91/$r;
  height: 26/$r;
  margin-left: 7/$r;
}
</style>
<style lang="scss">
$r: 750/16/1rem;
.news{
    .van-tabs__wrap{
    position: fixed;
    width: 100%;
    top: 84/$r;
    z-index: 99;
  }
   .van-tabs__line {
      width: 40/$r !important;
      height: 6/$r;
      background: #FF6B29;
      border-radius: 3/$r;
    }
   .van-cell:after{
  border: unset;
}
.van-badge--top-right{
  right: -5px;
}
}
</style>